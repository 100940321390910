<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="content">
        <a-card title="地图轨道">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="地图名称" prop="mapTrackName">
                  <a-input v-model="form.mapTrackName" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { mapAddOrEdit, mapInfo } from '@/api/map';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'mapTrackTypeForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  data() {
    return {
      form: {
        mapTrackName: '',
      },
      rules: {
        mapTrackName: [
          {
            required: true,
            message: '请输入地图名称'
          }
        ],
      },
    };
  },
  methods: {
    // 信息
    getData(id) {
      mapInfo(id).then((data) => {
        this.form = data;
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      mapAddOrEdit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
